<script>
import Profile from "@/components/Profile";

export default {
    name: "List",
	props:{
		isActiveList:{
			type: Boolean,
			required: true,
		},
	},
    computed: {
        userCathayInfo() {
            return this.$store.state.userCathayInfo;
        },
    },
    components: {
        Profile,
    },
	methods:{
		changeToLocaleString(str) {
			return Number(str).toLocaleString()
		},
		listToggle(){
			// console.log("listToggle")
			let vm=this
			vm.$emit("update:isActiveList",false)
		}
	}
}
</script>
<template src="./template.pug" lang="pug"></template>
<style src="./style.sass" lang="sass" scoped></style>