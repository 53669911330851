<script>

export default {
    name: "Survey",
	computed: {
		userCathayInfo() {
			return this.$store.state.userCathayInfo;
		},
	},
	methods:{
		surveyToggle(){
			// console.log("surveyToggle")
			let vm=this
			vm.$emit("update:isActiveSurvey",false)
		},
		dateStr(str){
			// console.log("dateStr",str.split("-"))
			return `${str.split("-")[0]}年${str.split("-")[1]}月${str.split("-")[2]}日`
		}
	}
}
</script>
<template src="./template.pug" lang="pug"></template>
<style src="./style.sass" lang="sass" scoped></style>