<script>
import { apiTasksUpdate } from "@/assets/js/api.js"; 
import AnswerResult from "@/components/AnswerResult"

export default {
	name: "MissionD",
	props:{
		nowMission:{
			type: Object,
			required: true,
		},
		nowJourney:{
			type: Object,
			required: true,
		},
	},
    computed: {
        userCathayInfo() {
            return this.$store.state.userCathayInfo;
        },
        hostUrl() {
            return this.$store.state.hostUrl;
        },
    },
	components:{
		AnswerResult
	},
	data(){
		return{
			isActivePopup: false,
			isLock: false,
			optionArr: [],
			answerInfo: {
				isTrue: null,
				response: null,
				image: null
			},
		}
	},
	watch:{
		isActivePopup: function(){
			let vm=this
			if(!vm.isActivePopup){
				setTimeout(() => {
					vm.$emit("update:nowMission",{type:null,index:null})
				}, 300);
			}
		}
	},
	methods:{
		sendAnswer(){
			let vm=this
			// console.log("sendAnswer")

			if(vm.optionArr.length===0){
				vm.$swal({
					text: '請選擇答案',
					confirmButtonText: '確定'
				})
				return	
			}else{
				vm.isLock=true	

				let answerStr=''

				for(let i=0;i<vm.optionArr.length;i++){
					answerStr+= vm.nowJourney.tasks[vm.nowMission.index-1].option[vm.optionArr[i]].txt +'|'
				}
				
				let data={
					journeyId: vm.nowJourney.id,
					type: vm.nowMission.type,
					index: vm.nowMission.index,
					name: vm.nowJourney.tasks[vm.nowMission.index-1].title,
					content: null,
					answer: answerStr,
					invType: null,
					invNum: null,
					invDate: null,
					invFile: null,
					randomNumber: null,
					cardNo: null,
					cardEncrypt: null,
				}

				let type="application/json"

				apiTasksUpdate(vm.hostUrl,data,vm.userCathayInfo.accessToken,type)
					.then(function(response){
						console.log(response)

						if(response.status===201){
							vm.isActivePopup=true
							vm.isLock=false
						}
					}).catch(function (err) {
						console.log(err);
						if(err.code!=='912'){
							vm.$swal({
								title: '錯誤',
								text: err.text,
								confirmButtonText: '確定'
							}).then(() => {
								vm.$emit("update:nowMission",{type:null,index:null})
							});
						}
					});
			}
		},
		addOption(target){
			let vm=this
			// console.log("addOption",target)
			
			if(vm.optionArr.indexOf(target)===-1){
				vm.optionArr.push(target)
			}else{
				vm.optionArr.splice(vm.optionArr.indexOf(target),1)
			}

			vm.answerInfo={
				isTrue: true,
				response: vm.nowJourney.tasks[vm.nowMission.index-1].response,
				image: vm.nowJourney.tasks[vm.nowMission.index-1].image
			}
		}
	}
}
</script>

<template src="./template.pug" lang="pug"></template>
<style src="./style.sass" lang="sass" scoped></style>