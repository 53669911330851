<script>
import { nextTick } from "vue";
import axios from "axios";
import { apiTasksUpdate } from "@/assets/js/api.js"; 
import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm.js';
import 'photoswipe/src/photoswipe.css'
import UploadResult from "@/components/UploadResult"

export default {
	name: "MissionA",
	props:{
		nowMission:{
			type: Object,
			required: true,
		},
		nowJourney:{
			type: Object,
			required: true,
		},
	}, 
    computed: {
        userCathayInfo() {
            return this.$store.state.userCathayInfo;
        },
        hostUrl() {
            return this.$store.state.hostUrl;
        },
    },
	components:{
		UploadResult
	},
	data(){
		return{
			isShowDes: true,
			isActivePopup: false,
			isLock: false,
			uploadType: null,
			note: "",
			previewArr: [],
			fileArr:[],
			// keyArr: [],
		}
	},
	watch:{
		isActivePopup: function(){
			let vm=this
			if(!vm.isActivePopup){
				setTimeout(() => {
					vm.$emit("update:nowMission",{type:null,index:null})
				}, 300);
			}
		}
	},
	mounted(){
		nextTick(() => {
			let vm=this
			if(vm.nowJourney.tasks[vm.nowMission.index-1].image!==''&&!vm.lightbox){
				vm.lightbox = new PhotoSwipeLightbox({
					gallery: '#subject',
					children: 'a',
					pswpModule: () => import('photoswipe'),
				});
				vm.lightbox.init();
			}
		});
	},
	unmounted() {
		let vm=this
		if (vm.lightbox) {
			vm.lightbox.destroy();
			vm.lightbox = null;
		}
	},
	methods:{
		async checkFile(){
			let vm=this
			// console.log("checkFile")

			if(vm.fileArr.length===0){
				vm.$swal({
					title: '格式錯誤',
					text: '請上傳照片／影片',
					confirmButtonText: '確定'
				})
				return
			}

			vm.isLock=true	
			// vm.keyArr= await vm.uploadFile(vm.fileArr[0]);
			vm.sendFile()

		},
		async uploadFile(file){
			let vm=this

				try {

					let preSigned = await axios.get(vm.hostUrl+"api/generate-presigned-url?v="+Math.random(),
						{
							headers: {
								"Authorization": `Bearer ${vm.userCathayInfo.accessToken}`,
								"Content-Type": "application/json",
							},
						})

					if (!preSigned) {
						throw new Error("Failed to get pre-signed URL");
					}
					
					try{
						// const response = await axios.put(preSigned.data.url, file, {
						// 	headers: {
						// 		'Content-Type': file.type,
						// 	},
						// })

						fetch(preSigned.data.url, {
							method: 'PUT',
							body: file,
							headers: new Headers({
								'Content-Type': file.type
							})
						})

						console.log('Upload Success');
					} catch(error){
						console.error(error);

						let data={
							error: error.message+'|'+JSON.stringify(error)
						}
						axios.post(vm.hostUrl+'api/logging',data)

					}

					return preSigned.data.key;

				} catch (error) {

					vm.$swal({
						title: '錯誤',
						text: '伺服器忙碌中，請稍後再試',
						confirmButtonText: '確定'
					})

				}

		},
		sendFile(){
			let vm=this
			// console.log("sendFile")

			let form = new FormData();
			form.append("journeyId", vm.nowJourney.id)
			form.append("type", vm.nowMission.type)
			form.append("index", vm.nowMission.index)
			form.append("name", vm.nowJourney.tasks[vm.nowMission.index-1].title)
			form.append("content", vm.fileArr[0])
			form.append("remark", vm.note)
			form.append("answer", null)
			form.append("invType", null)
			form.append("invNum", null)
			form.append("invDate", null)
			form.append("randomNumber", null)
			form.append("cardNo", null)
			form.append("cardEncrypt", null)
			form.append("invFile", null)

			vm.isShowDes=false

			let type="multipart/form-data"

			apiTasksUpdate(vm.hostUrl,form,vm.userCathayInfo.accessToken,type)
				.then(function(response){
					// console.log(response)

					if(response.status===201){
						vm.isActivePopup=true
						vm.isLock=false
						window.URL.revokeObjectURL(vm.previewArr[0])
					}
				}).catch(function (err) {
					console.log(err);
					if(err.code!=='912'){
						vm.$swal({
							title: '錯誤',
							text: err.text,
							confirmButtonText: '確定'
						}).then(() => {
							vm.$emit("update:nowMission",{type:null,index:null})
						});
					}
				});
		},
		chooseUploadType(target){
			let vm=this
			// console.log("chooseUploadType",target)

			vm.isShowDes=false
			vm.uploadType=target
		},
		//上傳圖片預覽
		previewImage(event) {
			// console.log("previewImage");
			var input = event.target;
			let maxFileSize=31457280 //30mb，因為ios上傳後檔案會比相簿顯示的大
			let vm=this

			if(input.files&&input.files[0]){
				vm.previewArr=[]
				vm.fileArr=[]
				// vm.keyArr=[]
				
				if(input.files[0].type.indexOf('image')!==-1){
					if(input.files.length>3){
						vm.$swal({
							title: '格式錯誤',
							text: '請上傳最多三張圖',
							confirmButtonText: '確定'
						})
						return	
					}else if (input.files.length >= 1) {
						for(let i = 0; i < input.files.length; i ++){
							if(input.files[i].size>maxFileSize){
								vm.$swal({
									title: '格式錯誤',
									text: '請上傳 20MB 以內的檔案',
									confirmButtonText: '確定'
								})
								return	
							}else{
								let headerArr=['ffd8ffe0','ffd8ffe1','ffd8ffe2','ffd8ffee','89504e47']
								let header=''
								let reader = new FileReader();
								reader.onload = function (e) {
									let imgArr = (new Uint8Array(e.target.result)).subarray(0, 4);   
									for (var j = 0; j < imgArr.length; j++) {
										header += imgArr[j].toString(16);
									} 
									if(headerArr.indexOf(header)===-1){
										vm.$swal({
											title: '格式錯誤',
											text: '請上傳 jpg或png的檔案',
											confirmButtonText: '確定'
										})
									}else{
										vm.previewArr.push(window.URL.createObjectURL(input.files[i]))
										vm.fileArr.push(input.files[i])
									}
								}
								reader.readAsArrayBuffer(input.files[i]);
							}
						}
					}
				}else if(input.files[0].type.indexOf('video')!==-1){
					if(input.files[0].size>maxFileSize){
						vm.$swal({
							title: '格式錯誤',
							text: '請上傳 20MB 以內的檔案',
							confirmButtonText: '確定'
						})
						return
					}else{
						vm.fileArr.push(input.files[0])
						vm.previewArr.push(window.URL.createObjectURL(input.files[0]))

						setTimeout(() => {
							let video=document.getElementsByTagName("video")[0]
							video.play()
							video.pause()
						}, 500);
					}				
				}		
			}
			
		},
		//圖片壓縮
		mixCanvas(img) {
			// console.log("mixCanvas");
			let vm=this
			var canvasPic = document.getElementsByClassName("canvas-pic")[0]
			var ctx = canvasPic.getContext("2d");

			canvasPic.width = img.width * 0.9;
			canvasPic.height = img.height * 0.9;
			ctx.drawImage(img, 0, 0, canvasPic.width, canvasPic.height);
			
			vm.previewArr.push(canvasPic.toDataURL("image/png",0.8))
		},
	}
}
</script>

<template src="./template.pug" lang="pug"></template>
<style src="./style.sass" lang="sass" scoped></style>