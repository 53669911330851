<script>
// import axios from "axios";
import { nextTick } from "vue";
import jsQR from "jsqr";
import { apiTasksUpdate } from "@/assets/js/api.js"; 
import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm.js';
import 'photoswipe/src/photoswipe.css'
import UploadResult from "@/components/UploadResult"

let frameRwq

export default {
	name: "MissionC",
	props:{
		nowMission:{
			type: Object,
			required: true,
		},
		nowJourney:{
			type: Object,
			required: true,
		},
	},
    computed: {
        userCathayInfo() {
            return this.$store.state.userCathayInfo;
        },
        hostUrl() {
            return this.$store.state.hostUrl;
        },
    },
	components:{
		UploadResult
	},
	data(){
		return{
			isShowDes: true,
			invoiceType: '',
			isScanOver: false,
			isLock: false,
			isActivePopup: false,
			userInvoiceData: {},
		}
	},
	watch:{
		isActivePopup: function(){
			let vm=this
			if(!vm.isActivePopup){
				setTimeout(() => {
					vm.$emit("update:nowMission",{type:null,index:null})
				}, 300);
			}
		}
	},
	mounted(){
		nextTick(() => {
			let vm=this
			if(vm.nowJourney.tasks[vm.nowMission.index-1].image!==''&&!vm.lightbox){
				vm.lightbox = new PhotoSwipeLightbox({
					gallery: '#subject',
					children: 'a',
					pswpModule: () => import('photoswipe'),
				});
				vm.lightbox.init();
			}
		});
	},
	unmounted() {
		let vm=this
		if (vm.lightbox) {
			vm.lightbox.destroy();
			vm.lightbox = null;
		}
	},
	beforeRouteLeave () {
		let vm = this;

		if(vm.invoiceType==="einvoice" && document.querySelector('video')){
			vm.scanOver()
		}
	},
	methods:{
		chooseUploadType(){
			let vm=this
			// console.log("chooseUploadType",target)

			vm.isShowDes=false
		},
		checkInput(target){
			// console.log("checkInput",target)
			let vm=this
			
			var validInvoice = /^[a-zA-Z]{2}[-]?[0-9]{8}$/;
			if (!validInvoice.test(vm.userInvoiceData.number)) {
				vm.$swal({
					title: '格式錯誤',
					text: '請確認發票號碼格式',
					confirmButtonText: '確定'
				})
				return
			}

			if (vm.userInvoiceData.date === ""||!vm.userInvoiceData.date) {
				vm.$swal({
					title: '格式錯誤',
					text: '請選擇發票日期',
					confirmButtonText: '確定'
				})
				return
			}

			if(target==="einvoice"){
				let validCode = /^\d{4}$/;
				if (!validCode.test(vm.userInvoiceData.random_code)) {
					vm.$swal({
						title: '格式錯誤',
						text: '請確認隨機碼格式',
						confirmButtonText: '確定'
					})
					return
				}
			}
			else if(target==="carrier"){
				var validNumber = /^\/[0-9A-Za-z.+-]{7}$/;
				if (!validNumber.test(vm.userInvoiceData.cardNo)) {
					vm.$swal({
						title: '格式錯誤',
						text: '請確認手機條碼格式',
						confirmButtonText: '確定'
					})
					return
				}

				if (vm.userInvoiceData.cardEncrypt === ""||!vm.userInvoiceData.cardEncrypt) {
					vm.$swal({
						title: '格式錯誤',
						text: '請輸入手機條碼驗證碼',
						confirmButtonText: '確定'
					})
					return
				}
			}

			if (vm.userInvoiceData.preview === ""||!vm.userInvoiceData.preview) {
				if(target==="einvoice"){
					vm.$swal({
						title: '格式錯誤',
						text: '請上傳發票照片',
						confirmButtonText: '確定'
					})
					return
				}
				else if(target==="carrier"){
					vm.$swal({
						title: '格式錯誤',
						text: '請上傳發票明細截圖',
						confirmButtonText: '確定'
					})
					return
				}
			}
			
			vm.generatePesignedUrl()
		},
		generatePesignedUrl(){
			let vm=this
			// console.log("generatePesignedUrl")
			vm.isLock=true	

			if(vm.userInvoiceData.file===''){
				vm.$swal({
					title: '格式錯誤',
					text: '請上傳照片／截圖',
					confirmButtonText: '確定'
				})
				return
			}

			vm.sendInvoice()

			// axios.get(vm.hostUrl+"api/generate-presigned-url",
			// 	{
			// 		headers: {
			// 			"Authorization": `Bearer ${vm.userCathayInfo.accessToken}`,
			// 			"Content-Type": "application/json",
			// 		},
			// 	})
			// 	.then(function(response){
			// 		console.log(response.data)

			// 		axios.put(response.data.url,vm.userInvoiceData.file,
			// 			{
			// 				headers:{
			// 					"Content-Type": "image/jpeg"
			// 				}
			// 			})
			// 			.then(function(res){
			// 				if(res.status===200){
			// 					vm.sendInvoice(response.data)
			// 				}
			// 			}).catch(function (err) {
			// 				console.log(err);
			// 			});
			// 	}).catch(function (error) {
			// 		console.log(error);
			// 	});
			
		},
		sendInvoice(){
			console.log("sendInvoice")
			let vm=this

			let form = new FormData();
			form.append("journeyId", vm.nowJourney.id)
			form.append("type", vm.nowMission.type)
			form.append("index", vm.nowMission.index)
			form.append("name", vm.nowJourney.tasks[vm.nowMission.index-1].title)
			form.append("content", null)
			form.append("answer", null)
			form.append("invType", vm.invoiceType ==='carrier'? 'card': 'invoice')
			form.append("invNum",  vm.userInvoiceData.number)
			form.append("invDate", vm.userInvoiceData.date)
			form.append("randomNumber", vm.userInvoiceData.random_code? vm.userInvoiceData.random_code:null)
			form.append("cardNo", vm.userInvoiceData.cardNo?vm.userInvoiceData.cardNo:null)
			form.append("cardEncrypt", vm.userInvoiceData.cardEncrypt?vm.userInvoiceData.cardEncrypt:null)
			form.append("invFile", vm.userInvoiceData.file)

			vm.isShowDes=false

			let type="multipart/form-data"

			apiTasksUpdate(vm.hostUrl,form,vm.userCathayInfo.accessToken,type)
				.then(function(response){
					console.log(response)

					if(response.status===201){
						vm.isActivePopup=true
						vm.isLock=false
					}
				}).catch(function (err) {
					console.log(err);
					if(err.code!=='912'){
						vm.$swal({
							title: '錯誤',
							text: err.text,
							confirmButtonText: '確定'
						}).then(() => {
							vm.$emit("update:nowMission",{type:null,index:null})
						});
					}else if(err.code==='912'){
						vm.$swal({
							title: '錯誤',
							text: err.text,
							confirmButtonText: '確定'
						}).then(() => {
							vm.isLock=false
						});
					}
				});
		},
		uppercase() {
			// console.log("uppercase")
			let vm=this

			if(vm.userInvoiceData.number)
				vm.userInvoiceData.number = vm.userInvoiceData.number.toUpperCase();
		},
		chooseType(target){
			let vm=this
			// console.log("chooseType:",target)

			vm.isScanOver=false
			vm.invoiceType=target
			vm.userInvoiceData={}

			if(target==''){
				if(document.querySelector('video')){
					vm.scanOver()
				}
			}else if(target==="einvoice"){
				nextTick(() => {
					vm.main()
				});
			}
		},
		//上傳圖片預覽
		previewImage(event) {
			// console.log("previewImage");
			var input = event.target;
			let vm=this

			let headerArr=['ffd8ffe0','ffd8ffe1','ffd8ffe2','ffd8ffee','89504e47']
			let header=''
			
			// vm.userInvoiceData.file=input.files[0]
			
			if (input.files && input.files[0]) {
				let reader = new FileReader();
				reader.onload = function (e) {
					let imgArr = (new Uint8Array(e.target.result)).subarray(0, 4);   
					for (var j = 0; j < imgArr.length; j++) {
						header += imgArr[j].toString(16);
					} 
					if(headerArr.indexOf(header)===-1){
						vm.$swal({
							title: '格式錯誤',
							text: '請上傳 jpg或png的檔案',
							confirmButtonText: '確定'
						})
					}else{
						reader.onload = (e) => {
							vm.userInvoiceData.preview= e.target.result
							vm.userInvoiceData.file=e.target.result
						};
						reader.readAsDataURL(input.files[0]);
					}
				}
				reader.readAsArrayBuffer(input.files[0]);
				
			}
		},
		//圖片壓縮
		mixCanvas(img) {
			// console.log("mixCanvas");
			let vm=this
			var canvasPic = document.getElementsByClassName("canvas-pic")[0]
			var ctx = canvasPic.getContext("2d");

			canvasPic.width = img.width * 0.9;
			canvasPic.height = img.height * 0.9;
			ctx.drawImage(img, 0, 0, canvasPic.width, canvasPic.height);

			vm.userInvoiceData.preview= canvasPic.toDataURL("image/png",0.8)
		},
		async main() {
			// console.log("main")
			let vm=this
			let video = document.querySelector('video');

			try {
				video.srcObject = await navigator.mediaDevices.getUserMedia(constraints)
				video.setAttribute("playsinline", true);
				await video.play();
				requestAnimationFrame(vm.tick);
			} catch (error) {
				alert("您拒絕授權使用相機")
			}
		},
		tick() {
			// console.log("tick")
			let vm=this
			const video = document.querySelector('video');
			const canvasElement = document.getElementById("invoiceCanvas");
			const ctx = canvasElement.getContext("2d");

			if (video.readyState === video.HAVE_ENOUGH_DATA) {
				canvasElement.hidden = false;
				canvasElement.width = video.videoWidth*0.9;
				canvasElement.height = video.videoHeight*0.9;
				ctx.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);
				let img = ctx.getImageData(0, 0, canvasElement.width, canvasElement.height);
				const code = jsQR(img.data, img.width, img.height, { inversionAttemps: "dontInvert" });
				if (code) {
					let found = {
						'number': "",
						'date': {
							'raw': "",
							'format': "",
							'y': "",
							'm': "",
							'd': "",
						},
						'random_code': ""
					};
					let str = code.data;

					found.number = str.substring(0, 10);
					found.date.raw = str.substring(10, 17);
					found.date.y = parseInt(found.date.raw.substring(0, found.date.raw.length - 4)) + 1911;
					found.date.y = found.date.y.toString();
					found.date.m = found.date.raw.substring(found.date.raw.length - 4, found.date.raw.length - 2);
					found.date.d = found.date.raw.substring(found.date.raw.length - 2, found.date.raw.length);
					found.date.format = found.date.y + "-" + found.date.m + "-" + found.date.d
					found.random_code = str.substring(17, 21);
					if (found.number && found.number.match(/[A-Z]{2}\d{8}/) && found.random_code.match(/\d{4}/)) {
						vm.stopMedia(found)
						return;
					} 
				}
			}
			frameRwq=requestAnimationFrame(vm.tick);
		},
		stopMedia(found) {
			// console.log("stopMedia")
			let vm=this
			let e = document.getElementById("invoiceCanvas"),
				n = document.querySelector("video");

			n.pause();

			vm.userInvoiceData = {
				type: "invoice",
				number: found.number,
				date: found.date.format,
				random_code: found.random_code,
				file: e.toDataURL("image/png"),
				preview: e.toDataURL("image/png")
			};
			// vm.dataURLtoFile(vm.userInvoiceData.file,'card.png')
			vm.isScanOver=true
		},
		scanOver(){
			// console.log("scanOver")
			let vm=this
			
			document.querySelector("video").pause()
			cancelAnimationFrame(frameRwq)
			nextTick(()=>{
				vm.isScanOver=true
			})
		},
		dataURLtoFile(dataurl, filename) {
			let vm=this
			var arr = dataurl.split(','),
				mime = arr[0].match(/:(.*?);/)[1],
				bstr = atob(arr[arr.length - 1]), 
				n = bstr.length, 
				u8arr = new Uint8Array(n);
			while(n--){
				u8arr[n] = bstr.charCodeAt(n);
			}
			vm.userInvoiceData.file=new File([u8arr], filename, {type:mime});
		}
	}
}

var constraints = {
	audio: !1,
	video: {
		width: {
			min: 400,
			ideal: 720,
			max: 1080
		},
		height: {
			min: 400,
			ideal: 720,
			max: 1080
		},
		aspectRatio: 1.25,
		facingMode: "environment"
	}
};
</script>

<template src="./template.pug" lang="pug"></template>
<style src="./style.sass" lang="sass" scoped></style>