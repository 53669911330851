<script>
export default {
	name: "Answer",
	props:{
		isActivePopup:{
			type: Boolean,
			required: true,
		},
		answerInfo:{
			type: Object,
			required: true,
		},
		nowMission:{
			type: Object,
			required: true,
		},
		nowJourney:{
			type: Object,
			required: true,
		},
	},
	methods:{
		popupToggle(){
			// console.log("popupToggle")
			let vm=this
			vm.$emit("update:isActivePopup",false)
		}
	}
}
</script>

<template src="./template.pug" lang="pug"></template>
<style src="./style.sass" lang="sass" scoped></style>