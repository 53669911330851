<script>
export default {
	name: "Info",
	props:{
		isActiveInfo:{
			type: Boolean,
			required: true,
		},
	},
	methods:{
		infoToggle(){
			// console.log("infoToggle")
			let vm=this
			vm.$emit("update:isActiveInfo",false)
		}
	}
}
</script>

<template src="./template.pug" lang="pug"></template>
<style src="./style.sass" lang="sass" scoped></style>