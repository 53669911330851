<script>
import { nextTick } from "vue";
import lottie from "lottie-web";
import { gsap } from 'gsap';
import { CSSPlugin } from 'gsap/CSSPlugin'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import scroll from "@/assets/json/scroll.json";
import info from "@/components/Info";
import Profile from "@/components/Profile";
import List from "@/components/List";
import Survey from "@/components/Survey";
import Ending from "@/components/Ending";

gsap.registerPlugin(CSSPlugin,ScrollToPlugin);

export default {
	name: "Home",
	data(){
		return{
			isActiveSkip: false,
			isActiveInfo: false,
			isActiveList: false,
			isActiveSurvey: false,
			isActiveEnding: false,
		}
	},
	components: {
		info,
		Profile,
		List,
		Survey,
		Ending
	},
	computed: {
		userCathayInfo() {
			return this.$store.state.userCathayInfo;
		},
		isActiveLoading() {
			return this.$store.state.isActiveLoading;
		},
		journeysWithStatus() {
			return this.$store.state.journeysWithStatus;
		},
	},
	watch: {
		isActiveLoading: function(){
			if(!this.isActiveLoading&&!this.isActiveSkip){
				//loading關閉後移動角色
				let vm = this; 
				nextTick(()=>{
					setTimeout(() => {
						if(vm.userCathayInfo.data&&vm.userCathayInfo.data.latestJourney!==null){
							if(vm.userCathayInfo.data.latestJourney!==vm.userCathayInfo.data.cursorJourney){
								vm.playerMove()
							}
						}
					}, 1600);
				}) 
			}
		},
		isActiveSkip: function(){
			if(!this.isActiveSkip){
				//有說明畫面時，關閉後移動角色
				let vm = this; 

				nextTick(()=>{
					setTimeout(() => {
						if(vm.userCathayInfo.data&&vm.userCathayInfo.data.latestJourney!==null){
							if(vm.userCathayInfo.data.latestJourney!==vm.userCathayInfo.data.cursorJourney){
								vm.playerMove()
							}
						}
							
					},1600);
				}) 
			}
		},
		userCathayInfo: function () {
			let vm = this;  

			if(localStorage.getItem("skip")===null){
				vm.isActiveSkip=true
			}

			nextTick(() => {
				if(vm.aniPlayer===undefined){
					if(vm.userCathayInfo.data&&vm.userCathayInfo.data.employee!==null){
						vm.playerJsonInit()
						vm.setPlayerPos()
						vm.timeCheck()
					}
				}

				if(vm.aniScroll===undefined){
					vm.aniScroll = lottie.loadAnimation({
						container: document.getElementById("aniScroll"),
						animationData: scroll,
						renderer: "svg",
						loop: true,
						autoplay: true, 
						name: "aniScroll", 
					});	
				}
			});
		},
		isActiveList: function (newV){
			let element = document.getElementsByTagName("body")[0];
			if(newV){
				element.classList.add("lock");
			}else{
				element.classList.remove("lock");
			}
		}
	},
	mounted() {
		nextTick(()=>{
			let vm = this;  

			if(vm.userCathayInfo.data&&vm.userCathayInfo.data.employee!==null){
				vm.playerJsonInit()

				if(vm.userCathayInfo.data.latestJourney!==vm.userCathayInfo.data.cursorJourney){
					vm.playerMove()
				}else{

					vm.setPlayerPos()
					const element = document.getElementsByClassName("active")[0];
					window.scrollTo({ top: element.offsetTop-'300' });
				}
			}
		})
	},
	beforeUnmount(){
		let vm = this;

		if(vm.aniPlayer!==undefined){
			vm.aniPlayer.destroy()
		}
		if(vm.aniScroll!==undefined){
			vm.aniScroll.destroy()
		}
	}, 
	methods:{
		timeCheck(){
			let vm=this
			console.log("timeCheck")

			let now = new Date();
			let reg = new RegExp("-", "g");

			if (new Date(vm.userCathayInfo.data.close_at.replace(reg, "/")) <= now) {
				vm.isActiveEnding=true
			}else if (new Date(vm.userCathayInfo.data.stop_at.replace(reg, "/")) <= now) {
				vm.isActiveSurvey=true
			}
		},
		playerJsonInit(){
			let vm = this;
			vm.aniPlayer = lottie.loadAnimation({
				container: document.getElementById("aniPlayer"),
				animationData: require(`@/assets/json/player${vm.userCathayInfo.data.employee.character}.json`),
				renderer: "svg",
				loop: true,
				autoplay: true, 
				name: "aniPlayer", 
			});
		},
		lightboxToggle(target){
			// console.log("lightboxToggle")
			let vm = this;
			
			vm[`isActive${target}`]=!vm[`isActive${target}`]
		},
		calculatePos(cursor){
			// console.log("calculatePos")
			let bottomArr=[2,6,11.5]
			let leftArr=[6,10,1]
			let pos={
				bottom: 0,
				left: 0
			}

			switch(cursor%3){
				case 0:
					pos.bottom = bottomArr[2]+14*(parseInt(cursor/3)-1)
					pos.left = leftArr[2]
					break
				case 1:
					pos.bottom = bottomArr[0]+14*parseInt(cursor/3)
					pos.left = leftArr[0]
					break
				case 2:
					pos.bottom = bottomArr[1]+14*parseInt(cursor/3)
					pos.left = leftArr[1]
					break
			}
			return pos
		},
		setPlayerPos(){
			// console.log("setPlayerPos")
			let vm = this;
			let player=document.getElementsByClassName("player-box")[0]
			let cursor=vm.userCathayInfo.data.cursorJourney
			let pos=vm.calculatePos(cursor)

			player.style.bottom= `${pos.bottom}%`
			player.style.left= `${pos.left}%`

			nextTick(()=>{
				gsap.to(window, { 
					duration: 0, 
					scrollTo:{ 
						y: `.flag${cursor}`, 
						offsetY: (window.innerHeight/2.3)
					}
				});
			})
		},
		playerMove(){
			// console.log("playerMove")
			let vm = this;

			let cursor=vm.userCathayInfo.data.latestJourney
			let pos=vm.calculatePos(cursor)

			gsap
				.timeline({
					repeat: 0
				})
				.addLabel("start")
				.to(
					window,
					{ 
						duration: .85, 
						scrollTo:{ 
							y: `.flag${cursor}`, 
							offsetY: (window.innerHeight/2.3)
						},
					},
					"start"
				)
				.to(
					".player-box",
					{
						duration: 1.85,
						bottom: `${pos.bottom}%`,
						ease: "expo.inOut",
					},
					"start"
				)
				.to(
					".player-box",
					{
						duration: 1.85,
						left: `${pos.left}%`,
						ease: "expo.inOut",
					},
					"start"
				)
		},
		changeToLocaleString(str) {
			return Number(str).toLocaleString()
		},
		skipClose(){
			// console.log("skipClose")
			let vm = this;
			
			vm.isActiveSkip=false
			localStorage.setItem("skip", false)
		}
	}
}
</script>
<template src="./template.pug" lang="pug"></template>
<style src="./style.sass" lang="sass" scoped></style>