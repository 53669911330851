<template lang="pug">
div
	transition(name="fadeLoading")
		Loading(v-show="isActiveLoading")
	router-view
</template>
<script>
import Loading from "@/components/Loading/index.vue";
import { nextTick } from "vue";
import liff from "@line/liff";

export default {
	components: {
		Loading,
	},
	computed: {
		isActiveLoading() {
			return this.$store.state.isActiveLoading;
		},
		isHaveCompanyCode() {
			return this.$store.state.isHaveCompanyCode;
		},
		userCathayInfo() {
			return this.$store.state.userCathayInfo;
		},
	},
	watch: {
		"$route":{
			handler: function(val) {
				let vm = this;  
				if(val.name==='Mission'){
					vm.$store.commit('loadingToggle',true)
				}
			},
			deep: true,
			immediate: true
		},
        userCathayInfo: function () {
			let vm = this;  
			if(vm.userCathayInfo.data.isBind){
				if(process.env.NODE_ENV === 'development'){
					nextTick(() => {
						setTimeout(() => {
							vm.$store.commit('loadingToggle',false)
						}, 1000);
					});
				}else{
					if(vm.userCathayInfo.data.isLaunch){
						nextTick(() => {
							setTimeout(() => {
								vm.$store.commit('loadingToggle',false)
							}, 1000);
						});
					}else{
						vm.$swal({
							title: "驗證成功",
							html: "遊戲登錄成功 !<br />歡迎加入國泰健康管理為您打造的健康叢林冒險旅程，旅程開始時會推播通知您可以開始遊戲唷!",
							confirmButtonText: '確定'
						}).then(() => {
							liff.closeWindow()
						});
					}
				}
				// vm.$store.dispatch("unBind");
			}
        },
        isHaveCompanyCode: function () {
			let vm = this;  
			if(process.env.NODE_ENV === 'development'){
				nextTick(() => {
					setTimeout(() => {
						vm.$store.commit('loadingToggle',false)
					}, 1000);
				});
			}else{
				if(!vm.isHaveCompanyCode){
					vm.$swal({
						title: "驗證失敗",
						html: "查無公司代碼",
						confirmButtonText: '確定'
					}).then(() => {
						liff.closeWindow()
					});
				}
			}
        },
	},
	mounted() {
		nextTick(() => {
			let vm = this;

			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty("--vh", `${vh}px`);

			try{
				screen.orientation.addEventListener("change", (event) => {
					const type = event.target.type;
					if(type.includes('landscape')){
						vm.$swal({
							title: "將手機轉為直式瀏覽，享受更好的互動體驗",
							customClass:{
								title: "orientation-title"
							},
							width: "70%",
							showConfirmButton: false,
							allowOutsideClick: false
						})
					}else if(type.includes('portrait')){
						if(vm.$swal.isVisible()){
							vm.$swal.close()
						}
					}
				});
			}catch (error) {
				console.error(error);
			}

			vm.$store.dispatch("initLineLiff");
			
		});
	},
}
</script>
<style lang="sass">
@import "@/assets/sass/_reset.sass"
@import "@/assets/sass/_variable.sass"
@import "@/assets/sass/_mixin.sass"
@import "@/assets/sass/_layout.sass"
//sweetalert css
.swal2-shown
	font-size: .2rem
	@include screen(790px)
		font-size: 4.67vmin
.swal2-container
	z-index: 99999999
	.swal2-popup
		width: 18em
	.swal2-title
		padding: 1em 1em 0em 1em
		margin-bottom: -.5em
		font-size: 1.3em
	.orientation-title
		font-size: 5vh
		line-height: 1.25
		padding: 1em 1em 1.25em 1em
	.swal2-html-container
		margin-top: 2em
		font-size: .85em
		letter-spacing: .01em
	.swal2-actions 
		margin-top: 0.65em
		button
			font-size: .8em
			background-color: #404669
			&:focus
				box-shadow: none
</style>
