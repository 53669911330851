import { createStore } from 'vuex'
import { nextTick } from "vue";
import liff from "@line/liff";
import axios from "axios";
import VConsole from "vconsole";
import router from "@/router"
import journeysData from "@/assets/json/journeys.json"
import journeysDevData from "@/assets/json/journeys-dev.json"
import journeysTestData from "@/assets/json/journeys-test.json"



if(process.env.NODE_ENV !== 'production'){
	const vConsole = new VConsole();
	console.log(vConsole);
}

export default createStore({
	strict: true,
	state: {
		isActiveLoading: true,
		isHaveCompanyCode: true,
		userLineInfo: {},
		userCathayInfo: {},
		journeysWithStatus: [],
		liffID: process.env.VUE_APP_liffID,
		baseUri: process.env.VUE_APP_baseUri,
		hostUrl: process.env.VUE_APP_hostUrl,
		journeysData: process.env.NODE_ENV === 'development' ? journeysDevData : process.env.NODE_ENV === 'test' ? journeysTestData : journeysData
	},
	mutations: {
		loadingToggle(state,value){
			state.isActiveLoading=value
		},
		setUserLineInfo(state,{idToken,userId,displayName,companyCode}){
			state.userLineInfo.idToken=idToken
			state.userLineInfo.userId=userId
			state.userLineInfo.displayName=displayName
			state.userLineInfo.companyCode=companyCode
		},
		setUserCathayInfo(state,data){
			let newData={
				accessToken: data.accessToken === undefined ? state.userCathayInfo.accessToken: data.accessToken,
				data: data.data
			}

			state.userCathayInfo=newData
		},
		setIsHaveCompanyCode(state,data){
			state.isHaveCompanyCode=data
		},
		setJourneysWithStatus(state,data){
			state.journeysWithStatus=data
		},
	},
	actions: {
		updateUserInfo(context){
			// console.log("updateUserInfo")
			let {state}=context

			axios.get(state.hostUrl+"api/me",
				{
					headers: {
						"Authorization": `Bearer ${state.userCathayInfo.accessToken}`,
						"Content-Type": "application/json",
					},
				})
				.then(function(response){
					// console.log(response.data)
					if(response.status===200){
						let data=response.data

						context.commit("setUserCathayInfo",data)
						context.dispatch("tasksMaping")

					}
				}).catch(function (err) {
					console.log(err);
				});
		},
		tasksMaping(context){
			// console.log("tasksMaping")
			let {state}=context

			let newObj=state.journeysData.map((element)=>{
				return JSON.parse(JSON.stringify(element))
			})

			
			state.userCathayInfo.data.journeys.forEach((journey,jIdx) => {
				Object.assign(newObj[jIdx], {"status": journey.status});
				Object.assign(newObj[jIdx], {"id": journey.id});
				
				journey.tasks.forEach(task => {
					let status=task.status
					let index=task.index-1
					Object.assign(newObj[jIdx].tasks[index], {"status":status});
					
				});
				if(jIdx===state.userCathayInfo.data.journeys.length-1){
					newObj.forEach((obj,oIdx) => {
						let journeyStatus=obj.status
						obj.tasks.forEach((task,tIdx) => {
							let status

							if(!task.status){
								if(journeyStatus==='expired'){
									status="expired"
								}else if(journeyStatus==='active'){
									status=null
								}
								Object.assign(newObj[oIdx].tasks[tIdx], {"status":status});
							}
						});
						if(oIdx===newObj.length-1){
							context.commit("setJourneysWithStatus",newObj)
						}
					});
				}
			});

		},
		unBind(context){
			// console.log("unBind")
			let {state}=context
			
			let data={
				idToken: state.userLineInfo.idToken,
				companyCode: router.currentRoute._value.query.companyCode ? router.currentRoute._value.query.companyCode :''
			}

			axios.post(state.hostUrl+"api/unbind",data,
				{
					headers: {
						"Authorization": `Bearer ${state.userCathayInfo.accessToken}`,
						"Content-Type": "application/json",
					},
				})
				.then(function(response){
					console.log(response.data)
				}).catch(function (err) {
					console.log(err);
				});
		},
		getUserCathayInfo(context){
			// console.log("getUserCathayInfo")
			let {state}=context
			
			let data={
				idToken: state.userLineInfo.idToken,
				companyCode: router.currentRoute._value.query.companyCode ? router.currentRoute._value.query.companyCode :''
			}

			axios.post(state.hostUrl+"api/login",data)
				.then(function(response){
					console.log(response.data)
					if(response.status===200){
						let data=response.data

						context.commit("setUserCathayInfo",data)

						if (!data.data.isBind) {
							// console.log("未綁定")

							router.push({ 
								name: 'Binding',
								query: { companyCode: router.currentRoute._value.query.companyCode} 
							})
							
							nextTick(() => {
								setTimeout(() => {
									context.commit('loadingToggle',false)
								}, 1000);
							});
						}else{
							context.dispatch("tasksMaping")
						}
					}
				}).catch(function (err) {
					console.log(err)
					if (err.response.status === 401) {
						document.cookie = 'LIFF_STORE:expires:' + context.state.liffID + '= ; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
						location.reload()
					}else if(err.response.data.error==='900'){
						context.commit('setIsHaveCompanyCode',false)
					}
				});
		},
		initLineLiff(context){
			console.log("initLineLiff")
			if (!context.state.liffID) {
				window.alert("LIFF ID error");
			} else {
				liff.init({
					liffId: context.state.liffID,
				}).then(function () {
					if (!liff.isLoggedIn()) {
						liff.login({
							redirectUri: context.state.baseUri,
						});
					} else {
						liff.getFriendship().then(response => {
							if(response.friendFlag){
								let idToken=liff.getIDToken()
								liff.getProfile().then(function (profile) {
									let userId=profile.userId;
									let displayName=profile.displayName;
									let companyCode=router.currentRoute._value.query.companyCode ? router.currentRoute._value.query.companyCode :''
									context.commit("setUserLineInfo",{idToken,userId,displayName,companyCode})
									context.dispatch("getUserCathayInfo")
								});
							}else{
								if(process.env.NODE_ENV === 'production'){
									// 正式站oa好友導流連結
									liff.openWindow({
										url: 'https://maac.io/2vQML'
									});
								}else{
									// 測試站
									liff.openWindow({
										url: 'https://maac.io/2vQVU'
									});
								}
							}
						})
					}
				}).catch(function (error) {
					console.log(error);

					let {state}=context
					let data={
						error:error.message
					}
					axios.post(state.hostUrl+'api/logging',data)
				});
			}
		},
	},
	modules: {
	}
})
