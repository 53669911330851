<script>
import { nextTick } from "vue";
import axios from "axios";
import MissionA from "@/components/MissionA"
import MissionB from "@/components/MissionB"
import MissionC from "@/components/MissionC"
import MissionD from "@/components/MissionD"
export default {
    name: "Mission",
	data(){
		return{
			nowMission: {
				type: null,
				index: null
			},
			nowJourney: {},
			isShowPage: false
		}
	},
	computed: {
		journeysWithStatus() {
			return this.$store.state.journeysWithStatus;
		},
        hostUrl() {
            return this.$store.state.hostUrl;
        },
        userCathayInfo() {
            return this.$store.state.userCathayInfo;
        },
	},
	components:{
		MissionA,
		MissionB,
		MissionC,
		MissionD
	},
	watch:{
		"nowJourney.step": function(){
			let vm=this
			vm.setNowJourney()
		},
		journeysWithStatus: function(){
			let vm=this
			vm.setNowJourney()
		},
	},
	mounted() {
		nextTick(() => {
			let vm=this
			vm.nowJourney.step=vm.$route.params.journey

			setTimeout(() => {
				vm.isShowPage=true
				setTimeout(() => {
					vm.$store.commit('loadingToggle',false)
				}, 1500);
			}, 500);
		});
	},
	methods:{
		setNowJourney(){
			// console.log("setNowJourney")
			let vm=this
			vm.journeysWithStatus.forEach(element => {
				if(element.step===Number(vm.nowJourney.step)){
					let obj=JSON.parse(JSON.stringify(element))
					Object.assign(vm.nowJourney, obj);
				}
			});
		},
		addZero(str){
			// console.log("addZero",str)

			if(Number(str)<10){
				return '0'+str
			}else{
				return str
			}
		},
		openMission(type,index){
			let vm=this
			// console.log("openMission",type,index)
			
			if(type){
				vm.nowMission.type=type
				vm.nowMission.index=index

				vm.sendClick()
			}else{
				vm.nowMission.type=null
				vm.nowMission.index=null
			}
		},
		sendClick(){
			// console.log("sendClick")
			let vm=this

			let clickArr=localStorage.getItem("clickArr")!==null?localStorage.getItem("clickArr").split(','):[]
			let clickTarget=`${vm.nowJourney.step}-${vm.nowMission.index}`

			if(clickArr.indexOf(clickTarget)===-1){
				clickArr.push(clickTarget)
				localStorage.setItem("clickArr",clickArr)

				let data={
					"journey": vm.nowJourney.step.toString(),
					"task": vm.nowMission.index.toString()
				}

				axios.post(vm.hostUrl+'api/click/task',data,{
					headers: {
						"Authorization": `Bearer ${vm.userCathayInfo.accessToken}`,
						"Content-Type": "application/json",
					},
				})
			}

		}
	}
}
</script>
<template src="./template.pug" lang="pug"></template>
<style src="./style.sass" lang="sass" scoped></style>