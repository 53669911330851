<script>
export default {
	name: "Profile",
	computed: {
		userCathayInfo() {
			return this.$store.state.userCathayInfo;
		},
	}
}
</script>

<template src="./template.pug" lang="pug"></template>
<style src="./style.sass" lang="sass" scoped></style>