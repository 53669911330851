import axios from 'axios';
import store from "../../store"

const taskRequest = axios.create();

taskRequest.interceptors.response.use(
	function (response) {
		store.dispatch("updateUserInfo")
		return response;
		
	}, 
	function (error) {
		store.dispatch("updateUserInfo")

		let err={
			code : error.response.data.error,
		}

		switch(error.response.data.error){
			case '910':
				err.text='任務已過期'
				break;
			case '911':
				err.text='重複執行任務'
				break;
			case '912':
				err.text='此張發票已登錄'
				break;
			default:
				err.text='系統忙碌中，請稍後再試'
				break;

		}
		return Promise.reject(err);
});

export const apiTasksUpdate = (hostUrl,data,token,type) => taskRequest.post(hostUrl+'api/tasks', data,
	{
		headers: {
			"Authorization": `Bearer ${token}`,
			"Content-Type": type,
		},
	}
);