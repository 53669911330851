import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home/index.vue'
import Mission from '../views/Mission/index.vue'

const routes = [
	{
		// 首頁
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		//綁定頁
		path: '/binding',
		name: 'Binding',
		component: () => import('../views/Binding/index.vue')
	},
	{
		// 任務遊戲頁
		path: '/mission/:journey(\\d+)',
		name: 'Mission',
		component: Mission
	},
	{
		path: "/:pathMatch(.*)*",
		redirect: "/",
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior(to) {
		if(to.fullPath!=="/"){
			return { 
				top: 0 ,
				behavior: 'smooth',
			}
		}
	},
})

export default router
