<script>
import liff from "@line/liff";

export default {
    name: "Ending",
	methods:{
		endingToggle(){
			console.log("endingToggle")
			liff.closeWindow() 
		}
	}
}
</script>
<template src="./template.pug" lang="pug"></template>
<style src="./style.sass" lang="sass" scoped></style>